module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Wanneer is de Omloop?","short_name":"Wanneer is de Omloop?","description":"Wanneer is de Omloop? Bijna. Hou vol. Van de maker van Kan ik een korte broek aan.nl","lang":"nl","start_url":"/","background_color":"#008dd2","theme_color":"#008dd2","display":"standalone","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"96f49fe4852def09506cc27751279d2b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
